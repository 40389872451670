table#trans-table {
    
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    border-radius: 10px;
    margin: 0;
    
    
  }

  
  .no-transaction{
    color: slategray;
  }
  /*  
  thead th:nth-child(1) {
    width: 30%;
  }
  
  thead th:nth-child(2) {
    width: 20%;
  }
  
  thead th:nth-child(3) {
    width: 15%;
  }

  
  
  thead th:nth-child(4) {
    width: 35%;
  } */
  
  /* th, td {
    padding: 20px;
  } */

  thead > .table-info > th{
    padding: 5px 10px 5px;
    background: #fa6e36;
    color: #fff;
}


.t-body > tr > td{
padding: 10px;
}
  thead#table-info tr{
      height: 10px;
  }

  caption{
      font-weight: bolder;
      width: 100%;
  }
  .dashboard-transaction{
      display: flex;
      /* width: 100%; */
      flex-direction: column;
      /* align-items: center;
      justify-content: center; */
      padding: 20px;
  }

  .links{
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #fa6e36;
      
  }

  .links > a{
    text-decoration: none;
    /* border: 1.4px solid #fa6e36; */
    padding: 5px 10px 5px;
    
    color: #fa6e36;
    /* background: rgb(243, 237, 237); */
    font-weight: bold;
  }

  .selected-transaction{
      border-radius: 4px;
      color: #fff;
      /* background: #fa6e36; */
      border: 1px solid #fa6e36;
      border-top-left-radius: 4px;
  border-top-right-radius: 4px;
      background: #FFF;
  }

  .links > a:hover{
      font-size: 16.5px;
  }

.selected-transaction > a{
  color: #fff !important;
  background: red;
}

.lds-facebook-item {
  position: relative;
  width: 80px;
  height: 80px;
  margin-left: auto;
  margin-right: auto;
}
.lds-facebook-item div {
  display: inline-block;
  position: absolute;
  
  width: 16px;
  background: #e63813;
  animation: lds-facebook-item 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook-item div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook-item div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook-item div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook-item {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}

thead{
  background-color: #fa6e36;
  color: white;
}

.t-style{
  padding: 10px;
}
.viewLink {
  text-decoration: none;
  color: #FFF;
}

.viewLink:hover{
  text-decoration: none;
  color: inherit;
  font-size: 18px;
}
