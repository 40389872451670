.successimg{
    position: relative;
    display: block;
    margin-right: auto;
    margin-left: auto;
    top: 21px;
}
p {
    text-align: center;
    font-size: 25px;
    position: relative;
    top: 40px;
}
.title {
    text-align: center;
    font-size: 32px;
    position: relative;
    top: 54px;
}
.buttondiv {
    position: relative;
    text-align:center;
    top: 38px;
    font-size: 16px;
}
.details {
    position: relative;
    top: 42px;
    font-size: 16px;
    text-align: center;
    line-height: 1.5;
}